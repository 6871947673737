import React,{useState, useMemo, useEffect} from "react";
import ReactDOM from "react-dom/client";
import SddHeader from './SddHeader';
import UserBlogs from './UserBlogs';
import LoginPage from './Login';
import { Navigate,useLocation,useNavigate } from "react-router-dom";
import NewBlog from "./NewBlog";

const Blogs = (props) => {
       const location  = useLocation();
       const {loggedIn,loggedInUserId, token} = (location.state) ? location.state : {};
       const [userBlogs, setUserBlogs] = useState({});
        const {REACT_APP_API_URL} = process.env;
       const history = useNavigate();

        async function ab(){
                    const requestOptions = {
                    method: "GET",
                    headers: { "Content-Type": "application/json",'Accept':'application/json',
                               'Authorization': `Bearer ${token}`},
                    mode:"cors",
                    };
                    try{
                   const response = await fetch(`${REACT_APP_API_URL}/blog/` + loggedInUserId,requestOptions);
                   const resJson = await response.json();
                   console.log(resJson);
                   setUserBlogs(resJson);
                   }catch(e){
                    history("/login");
                   }
        }
        useEffect( () =>{
           ab();
        },[]);
      // const userBlogs = useMemo (() => userBLogsFetch(), []);
      const display = JSON.stringify(userBlogs, null, 2);
      return(
        <div key="blogs" class="blogs">
            <SddHeader> </SddHeader>
            {loggedIn && (
             <React.Fragment>
                <NewBlog loggedIn={loggedIn} loggedInUserId={loggedInUserId} stateArgs={location.state} > </NewBlog>
                <UserBlogs className="{p-3 text-bg-dark}" stateArgs={location.state} userB= {userBlogs}/>
            </React.Fragment>
            )}
            {!loggedIn && (
                 <Navigate to="/login" />
            )}
        </div>
       );
    }

export default Blogs;