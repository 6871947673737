import React,{useState} from "react";
import ReactDOM from "react-dom/client";
import SddHeader from './SddHeader';
import LoginPage from './Login';
import { Navigate,useLocation, Link, useNavigate } from "react-router-dom";

const UserBlogSummary = (props) => {
       const location  = useLocation();
       const {loggedIn,loggedInUserId, token} = (location.state) ? location.state : {};
       const display = JSON.stringify(props, null, 2);
       const history = useNavigate();
       const onClickHandler = (e) => {
            e.preventDefault()
            history('/blog/'+loggedInUserId + "/" + props.blog.blogId,
             {state: {loggedIn: true, username: loggedInUserId, loggedInUserId:loggedInUserId, token:token}});
       }
      return(
        <div key="userBlogSummary" class="userBlogSummary">
            <Link to= {"/blog/" +loggedInUserId + "/" + props.blog.blogId} onClick={onClickHandler}> [{props.blog.blogId}]:{"\t"} {"\t"}  {props.blog.blogTitle}</Link>
        </div>
       );
    }

export default UserBlogSummary;