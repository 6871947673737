import React, { Component } from "react";
import { Alert } from "reactstrap";
//import img1 from "../images/img1.png";
//import img2 from "../images/img2.png";

class SideNav extends Component {
  state = {};

  render() {
    //const images = require.context(
    // "/Users/prasoon/work/java/design/src/main/resources/static",
    //  true
    //);
    return (
      <ul>
        {this.props.sampleDesigns.map((sddType) => (
          <li key={sddType}>
            <a>
              <img
                //src={images(`./${sddType.id}/${sddType.imgUrl}`)}
                src={
                  process.env.PUBLIC_URL +
                  "/static/" +
                  sddType.type +
                  "/" +
                  sddType.url
                }
                onClick={() => {
                  this.props.sideNavClickHandler(sddType.txt);
                }}
                style={{
                  maxWidth: "150px",
                  height: "auto",
                  padding: "15px",
                  background: "#EAFFF1",
                  cursor: "pointer"
                }}
              ></img>
            </a>
          </li>
        ))}
      </ul>
    );
  }
}

export default SideNav;
