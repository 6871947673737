import React,{useState} from "react";
import ReactDOM from "react-dom/client";
import SddHeader from './SddHeader';
import { Navigate,useLocation,useNavigate } from "react-router-dom";
import { useCurrentEditor } from '@tiptap/react'
import { useParams } from 'react-router';

const SaveEdit = (props) =>{
    const { editor } = useCurrentEditor();
    const params= useParams();
    const {REACT_APP_API_URL} = process.env;
    const location  = useLocation();
    const history = useNavigate();
    const {loggedIn,loggedInUserId, token} = (location.state) ? location.state : {};
    const  handleSave = (e) =>{
        e.preventDefault();
        const display = JSON.stringify(editor.getJSON(), null, 2);
        const userId = params.userId;
        const blogId = params.blogId;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json",
                       'Authorization': `Bearer ${token}`,},
            body: JSON.stringify({content: display, userId:userId, blogId: blogId}),
            mode:"cors",
        };

        return fetch(`${REACT_APP_API_URL}/blog/save`, requestOptions)
         .then((response) => response.json())
         .then((data) => {
               console.log(data);
               history('/blog/'+loggedInUserId, {state: {loggedIn: true, username: loggedInUserId, loggedInUserId:loggedInUserId,
               			        token:token}});
               })

    }
    return (
        <button onClick ={handleSave} type="submit"> Save</button>
    )
}
export default SaveEdit;