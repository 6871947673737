import React, { Component } from "react";
import { Image } from "react-native";

const {REACT_APP_API_URL} = process.env;

class EditImg extends Component {
  state = { image: "" };

  componentWillReceiveProps(props) {
    const { designTxt } = this.props;
    if (props.designTxt != designTxt) {
      this.loadImage(props.designTxt);
    }
  }
  loadImage = (designText) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        config: String("@startuml \n skinparam monochrome true \n " + designText + " \n@enduml").replace(
          (/\\n/g, "\\n")
        ),
      }),
    };
    fetch(`${REACT_APP_API_URL}/sequence`, requestOptions)
      .then((response) => response.arrayBuffer())
      .then((data) => {
        const base64string = btoa(String.fromCharCode(...new Uint8Array(data)));
        if (base64string) {
          this.setState({
            image: "data:image/png;base64," + base64string,
            loaded: true,
          });
        }
      })
      .catch((err) => console.log(err.message));
  };
  render() {
    return <img key="imgid" src={this.state.loaded && this.state.image} style={{overflowX: 'scroll', maxWidth:'90%'}}></img>;
  }
}

export default EditImg;
