import React,{useState, useEffect} from "react";
import ReactDOM from "react-dom/client";
import SddHeader from './SddHeader';
import BlogMain from './BlogMain';
import LoginPage from './Login';
import { Navigate,useLocation, useParams,useNavigate } from "react-router-dom";


const Blog = (props) => {
       const location  = useLocation();
       const {loggedIn,loggedInUserId, token} = (location.state) ? location.state : {};
       const {userId, blogId} = useParams();
       const [userBlog, setUserBlog] = useState({});
       const {REACT_APP_API_URL} = process.env;
       const history = useNavigate();

       async function ab(){
           const requestOptions = {
           method: "GET",
           headers: { "Content-Type": "application/json",'Accept':'application/json',
                      'Authorization': `Bearer ${token}`},
           mode:"cors",
           };
           try{
                const response = await fetch(`${REACT_APP_API_URL}/blog/` + userId + "/" + blogId, requestOptions);
                const resJson = await response.json();
                console.log(resJson);
                setUserBlog(resJson);
          }catch(error){
            history("/login");
          }
       }
       useEffect( () =>{
          ab();
       },[]);
      return(
        <div key="blog" class="blog">
            <SddHeader> </SddHeader>
            {loggedIn && (
                <BlogMain className="{p-3 text-bg-dark}" stateArgs={location.state} content = {userBlog} loggedIn={loggedIn}/>
            )}
            {!loggedIn && (
                 <Navigate to="/login" />
            )}
        </div>
       );
    }

export default Blog;