import './styles.scss';
import { EditorProvider, FloatingMenu, BubbleMenu, Editor, useCurrentEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import React, { useEffect, useState } from 'react';

const LoadInitialContent = (props) => {
     const { editor } = useCurrentEditor();
     const display = JSON.stringify(props.content, null, 2);
     useEffect(() => {
            if(props.content && Object.keys(props.content).length != 0 && editor){
             editor.commands.setContent(props.content);
            }
         },[props.content]);
    return(<p></p>);
}
export default LoadInitialContent;