import React, { Component, useState } from "react";
//import sequence_img from "../images/sequence_diagram_icon.png";
//import sequence_img from "../images";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import PopupElement from "./PopupElement";
import Modal from 'react-modal';
//import images from "static/";

const {REACT_APP_API_URL} = process.env;

class WelcomePopUp extends Component {
  state = {
    sddTypes: [],
    open: true,
  };

    customStyles = {
  content: {
    top: '10%',
    left: '20%',
    right: '20%',
    bottom: 'auto',
    overflow: 'scroll',
    height: '85%',
  },
};

  typeNameLoader = () => {
    fetch(`${REACT_APP_API_URL}/types`, {mode: 'cors'})
      .then((response) => response.json())
      .then((data) => this.setState({ sddTypes: data.types }));
  };

  componentDidMount() {
    this.typeNameLoader();
  }

   closeModal = () => {
        this.setState({ open: false });
   };


  render() {
    return (
    <Modal isOpen={this.state.open} shouldCloseOnOverlayClick={false}
        style={this.customStyles}
        overlayStyle={{position: "relative", background: "rgb(146 207 166 / 50%)", backdropFilter:"blur(2px)"}}
        contentStyle={{border: "5px",top: "20%", left:"25%",position:"relative", overflow: "scroll" }}
      >
          <ul class="pop-up-ul">
            {this.state.sddTypes.map((sddType) => {
              return [
                <li key={sddType}>
                  <div>
                    <PopupElement
                      sddType={sddType}
                      customClickHandler={() => {
                        this.props.imageClickHandler(sddType.id);
                        this.setState({ open: false });
                        this.closeModal();
                      }}
                    ></PopupElement>
                  </div>
                </li>,
              ];
            })}
          </ul>
      </Modal>
    );
  }
}

export default WelcomePopUp;
