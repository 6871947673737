import './styles.scss';
import { EditorProvider, FloatingMenu, BubbleMenu, Editor, useCurrentEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import React, { useEffect, useState } from 'react';
import SaveEdit from "./SaveEdit";
import Logout from "./Logout";
import LoadInitialContent from "./LoadInitialContent";
import {
	MDBContainer
} from 'mdb-react-ui-kit';

// define your extension array
const extensions = [StarterKit]


const Tiptap = (props) => {
const [editable, setEditable] = useState(true);
//const content = '<p>Hello World!</p>'

const editor1 = new Editor({
  editable,
  // intial content
  content: `<p>Type your content here...</p>`,
  extensions: [StarterKit],
  // triggered on every change
  onUpdate: ({ editor }) => {
    const json = editor.getJSON()
    // send the content to an API here
  },
})
  const display = JSON.stringify(props.content, null, 2);

  return (
    <React.Fragment>
        {props.content != undefined &&
        <div className="d-flex justify-content-center align-items-left vh-100">
        			<div className="border rounded-lg p-4" style={{ width: '100%', height: 'auto', marginTop:'2px' }}>
        				<MDBContainer className="p-3">
                            <EditorProvider editable = {props.loggedIn} extensions={extensions} content = "type content here..." >
                              <LoadInitialContent content ={props.content}> </LoadInitialContent>
                              <Logout> </Logout>
                              <SaveEdit userId= {props.loggedIn} blogId= {props.loggedIn}> </SaveEdit>
                              <FloatingMenu editor={null}></FloatingMenu>
                              <BubbleMenu editor={null}>This is the bubble menu</BubbleMenu>
                            </EditorProvider>
                        </MDBContainer>
                    </div>
         </div>
        }
    </React.Fragment>
  )
}

export default Tiptap;
