import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import Start from './components/Start';
import Blogs from './components/Blogs';
import Blog from './components/Blog';
import SignupPage from './components/Signup';
import LoginPage from './components/Login';


import { createRoot } from "react-dom/client";
import {
createRoutesFromElements,
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";


const route = createBrowserRouter(
  createRoutesFromElements(
  <React.Fragment>
        <Route path="/" element={<Start />}>
          <Route path="/edit/:orgId/:docType/:docId" element={<Start />} />
        </Route>
        <Route path="/blog/:userId" element={<Blogs />} />
        <Route path="/blog/:userId/:blogId" element={<Blog />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/login" element={<LoginPage />} />
   </React.Fragment>
  )
);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RouterProvider router={route} />);



/*const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <SddHeader> </SddHeader>
    <SddMain className="{p-3 text-bg-dark}"/>
  </React.StrictMode>
);*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
