import React,{Component} from "react";
import Modal from 'react-modal';
import CloseButton from 'react-bootstrap/CloseButton';

const {REACT_APP_API_URL} = process.env;

class FeedBackPopUp extends Component{
    state = {open: false,
             email:"",
             feedback:"",
             disableButton:false,
             };

    openClickHandher = () =>{
        this.setState({open:true});
    }

    closeHandler = () => {
        this.setState({open:false});
    }

    emailInputHandler =(e) =>{
        this.setState({email:e.target.value});
    }

    feedbackInputHandler =(e) =>{
        this.setState({feedback:e.target.value});
    }

    submitButtonHandler =() =>{
        const requestOptions = {
                           method: "POST",
                           headers: { "Content-Type": "application/json" },
                           body: JSON.stringify({
                             email: String(this.state.email),
                             feedback: String(this.state.feedback),
                           }),
                         };
        fetch(`${REACT_APP_API_URL}/feedback`, requestOptions)
        .then(response => response.json())
        .then(data => this.setState({disableButton:true}))
        .catch((err) => console.log(err.message));
    }
     render(){
        return<div>
         <a onClick={this.openClickHandher} style={{color:'red', marginRight:"5px", cursor: "pointer"}}>Feedback>> </a>
         <Modal isOpen={this.state.open} shouldCloseOnOverlayClick={false}
         >
            <CloseButton onClick= {this.closeHandler} style= {{float:'right'}}>
            </CloseButton>
             <a style={{fontWeight:"bold"}} > Your feedback will help improve this tool. Please share your valuable feedback below.</a>
             <div class= "feedbackPopupContent" style={{position:"relative", display:"flex", flexDirection:"column", padding:"5%"}}>
                <div style={{flexDirection:"row"}}>
                    <label style={{width:"100px"}}>Email  : </label><input style={{width:"200px"}} value={this.state.email} onChange={this.emailInputHandler}/>
                </div>

                <div style={{flexDirection:"row", marginTop:"10px"}}>
                    <label style={{width:"100px"}}>Feedback: </label><textarea style={{width:"700px", height:"300px"}} value={this.state.feedback} onChange={this.feedbackInputHandler}/>
                </div>
                <div>
                    <button disabled={this.state.disableButton} name="submit" style={{width:"70px",height:"30px", marginTop:"10px", align:"middle"}} onClick={this.submitButtonHandler}>Submit</button>
                </div>
             </div>
         </Modal>
         </div>;
    }
}
export default FeedBackPopUp;