import React, { Component, useState } from "react";

class PopupElement extends Component {
  render() {
    return (
      <div>
        <a>
          <img
            src={
              process.env.PUBLIC_URL +
              "/static/" +
              this.props.sddType.id +
              "/" +
              this.props.sddType.imgUrl +
              ""
            }
            style={{ maxWidth: "150px", height: "auto",cursor: "pointer"}}
            onClick={this.props.customClickHandler}
          ></img>
        </a>
        <p>{this.props.sddType.id}</p>
      </div>
    );
  }
}

export default PopupElement;
