import { Component } from "react";
import FeedBackPopUp from "./FeedBackPopUp"

class SddHeader extends Component {
  render() {
    return (
      <div class="header-background">
        <div class="logo">
          <img class="logo-img" src="/logo-2.jpeg"></img>
        </div>
        <div style={{align:"right", float:"right"}}>
            <FeedBackPopUp> </FeedBackPopUp>
        </div>
      </div>
    );
  }
}

export default SddHeader;
