import React,{useState} from "react";
import ReactDOM from "react-dom/client";
import SddHeader from './SddHeader';
import { Navigate,useLocation } from "react-router-dom";
import { useCurrentEditor } from '@tiptap/react'
import { useNavigate } from 'react-router-dom';

function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

const NewBlog = (props) =>{
    const { editor } = useCurrentEditor();
    const history = useNavigate();
    const location  = useLocation();
    const  handleSave = (e) =>{
        e.preventDefault();
        const {loggedIn,loggedInUserId, token} = (location.state) ? location.state : {};
        const blogId= makeid(5);
        history('/blog/'+ props.loggedInUserId + '/' + blogId, {state: {loggedIn: true,
        loggedInUserId: loggedInUserId,  blogId: blogId, token:token}});
    }
    return (
        <button onClick ={handleSave} type="submit"> Create New</button>
    )
}
export default NewBlog;