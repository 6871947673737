import {React, Component} from "react";
import ReactDOM from "react-dom/client";
import Tiptap from './Tiptap';
//import EditorTip from './EditorTip';

 const BlogMain = (props) => {
     const display = JSON.stringify(props, null, 2);
      return(
        <div key="blogMain" class="blog_main">
        <Tiptap content={props.content} loggedIn={props.loggedIn} > </Tiptap>
        </div>
       );
}
export default BlogMain;