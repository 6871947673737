import React,{useState} from "react";
import ReactDOM from "react-dom/client";
import SddHeader from './SddHeader';
import LoginPage from './Login';
import { Navigate,useLocation } from "react-router-dom";
import UserBlogSummary from './UserBlogSummary';

const UserBlogs = (props) => {
       const location  = useLocation();
       const {loggedIn,loggedInUserId} = (location.state) ? location.state : {};
        const display = JSON.stringify(props.userB.blogSummary, null, 2);
      return(
        <div key="userBlog" class="userBlogs">
            <ul>
            { props &&
             props.userB &&
              props.userB.blogSummary &&
               props.userB.blogSummary.map( function(blog, i ){
                   return <li key={i}><UserBlogSummary  blog= {blog}> </UserBlogSummary></li>

                })
            }
            </ul>
        </div>
       );
    }

export default UserBlogs;