import React, { Component, useRef } from "react";
import "bootstrap/dist/css/bootstrap.css";
import WelcomePopUp from "./WelcomePopIUp";
import ReactDOM from "react-dom/client";
import SideNav from "./SideNav";
import EditImg from "./EditImg";

import {
  SafeAreaView,
  StyleSheet,
  ScrollView,
  StatusBar,
  TextInput,
  View,
} from "react-native";

const {REACT_APP_API_URL} = process.env;

class SddMain extends Component {
  state = {
    designType: "",
    sampleDesigns: [],
    designText: "",
    showMain: false,
    image: "",
    loaded: false,
  };

  textInputref = React.createRef();

  imageClickHandler = (sddType) => {
    if (String(sddType) != "") {
    fetch(`${REACT_APP_API_URL}/samples/` + sddType, {mode: 'cors'})
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ sampleDesigns: data.examples, showMain: true });
        //this.loadDesignPage();
      })
      .catch((err) => console.log(err.message));
    }
  };

  popupElementClickHandler = (sddObject) => {};

  sideNavClickHandler = (designText) => {
    this.setState({ designText: this.state.designText + " \n " + designText });
    //this.loadImage();
  };

  refreshClickHandler = (designText) => {
    this.setState({ designText: designText });
    //this.loadImage();
  };

  loadDesignPage = () => {
    const root = ReactDOM.createRoot(document.getElementById("root"));
    root.render();
  };

  downloadImage = (url, name = 'design') => {
       const requestOptions = {
                   method: "POST",
                   headers: { "Content-Type": "application/json" },
                   body: JSON.stringify({
                     config: String("@startuml\n  skinparam monochrome true \n " + this.state.designText + " \n@enduml").replace(
                       (/\\n/g, "\\n")
                     ),
                   }),
                 };
          return fetch(`${REACT_APP_API_URL}/sequence`, requestOptions)
          .then(resp => resp.blob())
          .then(blob => {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.style.display = 'none';
              a.href = url;
              a.download = name;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);

              return Promise.resolve();
          })
  }

  downloadImg = () => {
    if(this.state.designText !== ""){
        this.downloadImage(`${REACT_APP_API_URL}/sequence`, 'fileName')
        .then(() => console.log('ok'))
        .catch(() => console.log('error'))
    }
  };

  render() {
    return (
      <div key="start" class="start" style={{ bgcolor: "red" }}>
        <div key="welcomePopUp">
          <WelcomePopUp
            imageClickHandler={this.imageClickHandler}
          ></WelcomePopUp>
        </div>

        {this.state.showMain && (
          <View
            style={{
              flexDirection: "row",
              height: 700,
              padding: 20,
            }}
          >
            <scrollView style={{ flex: 0.2, border: "1px solid green", borderRadius:5 }}>
              <div
                key="side_nav"
                style={{ width: "100%", height: "100%", overflow: "scroll" }}
              >
               <label style = {{display:'flex', justifyContent: 'center', background:"lightGreen"}}> Samples </label>
                <SideNav
                  sampleDesigns={this.state.sampleDesigns}
                  sideNavClickHandler={this.sideNavClickHandler}
                ></SideNav>
              </div>
            </scrollView>
            <scrollView
              style={{ flex: 0.2, border: "1px solid green", borderRadius: 5}}
            >
            <label style = {{display:'flex', justifyContent: 'center', background:"lightGreen"}}> Editor </label>
            <div key="editor" style={{ width: "100%", height: "100%", padding: "0.5%", overflow: "scroll" }} >
              <TextInput
                style={{ width: "100%", height: "100%", fontFamily: 'OpenSans-Regular', border: 'none',outline:"none" }}
                value={this.state.designText}
                onChangeText={this.refreshClickHandler}
                multiline
                editable
                ref={this.textInputref}
              ></TextInput>
            </div>
            </scrollView>
            <ScrollView style={{ flex: 0.6, border: "1px solid green", borderRadius: 5}}>
            <label style = {{display:'flex', justifyContent: 'center', background:"lightGreen"}}> Generated Image </label>
              <div key="image_holder" style={{ width: "100%", height: "100%" }}>
                <EditImg
                  image={this.state.image}
                  loaded={this.state.loaded}
                  designTxt={this.state.designText}
                ></EditImg>
                <img src={process.env.PUBLIC_URL + "/static/download.png"} style={{height:'20px', float:'right', marginRight:"3%", marginTop:"1%", cursor: "pointer"}}
                 onClick={this.downloadImg}/>
              </div>
            </ScrollView>
          </View>
        )}
      </div>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: StatusBar.currentHeight,
  },
  scrollView: {
    backgroundColor: "pink",
    marginHorizontal: 20,
  },
  text: {
    fontSize: 42,
  },
});

export default SddMain;
