import React,{useState} from "react";
import ReactDOM from "react-dom/client";
import SddHeader from './SddHeader';
import { Navigate,useLocation } from "react-router-dom";
const Logout = () =>{
    const  handleLogout = (e) =>{
        e.preventDefault();
        alert("logout clicked");
    }
    return (
        <button onClick ={handleLogout} type="submit"> Logout</button>
    )
}
export default Logout;