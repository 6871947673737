import {React, Component} from "react";
import ReactDOM from "react-dom/client";
import SddHeader from './SddHeader';
import SddMain from './SddMain';

class Start extends Component{
    render(){
      return(
        <div key="startMain" class="start_main">
            <SddHeader> </SddHeader>
            <SddMain className="{p-3 text-bg-dark}"/>
        </div>
       );
    }
}
export default Start;